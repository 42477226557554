@import '../../../assets/scss/variables';

.cx-todo-item {
  display: flex;
  padding: 10px 5px 10px 0;
  border-bottom: 1px solid #ccc;
  //border-top: 1px solid red;

  > div {
    flex: 1 1 auto;
    display: flex;
    div:first-child {
      flex: 0 0 auto;
      margin: 10px 0 0 10px;
    }
    label {
      flex: 1 1 auto;
      margin: 10px 0 0 10px;
      > small {
        display: block;
        font-size: 12px;
        padding: 10px 0 0 0;
      }
    }
  }

  .cx-todo-item-settings {
    flex: 0 0 auto;
    > div {
      margin: 0 !important;
      background-color: #fff;
      &:hover {
        background-color: #fff;
      }
    }
  }

  $squareSize: 20px;
  // 1
  .todo-item-active {
    height: $squareSize;
    width: $squareSize;
    border: 2px solid $gray4;
    border-radius: 2;
  }

  // 2
  .todo-item-inprogress {
    height: $squareSize;
    width: $squareSize;
    background-color: $gray4;
    border: 2px solid $gray4;
  }

  // 3
  .todo-item-done {
    > div {
      background-color: $lightGray4;
      border-color: $lightGray4;
    }
    > label {
      text-decoration: line-through;
      color: $lightGray4;
    }
  }
}
