@import '../../../assets/scss/_media-queries.scss', '../../../assets/scss/_variables.scss';

$sideBarSizeXSmall: 250px;
$sideBarSizeSmall: 300px;

.cx-sidebar {
  background-color: $white2;
  // box-shadow: 0px 0px 28px 0px rgba(82, 63, 105, 0.08);

  width: $sideBarSizeXSmall;
  left: -$sideBarSizeXSmall;
  position: fixed;
  top: 0;
  bottom: 0;
  -webkit-transition: left 0.3s ease;
  transition: left 0.3s ease;
  z-index: 20;
  display: flex;
  flex-direction: column;

  > footer {
    margin-bottom: auto;
    flex: 0 0 19px;
    background-color: $red1;
  }
}

.cx-sidebar-overlay {
  background-color: lighten(#000, 40%);
  position: absolute;
  display: none;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: 5;
  opacity: 0.8;
  -webkit-transition: opacity 0.3s ease;
  transition: opacity 0.3s ease;
  z-index: 15;
}

.cx-sidebar-open {
  .cx-sidebar {
    left: 0;
    &:after {
      box-shadow: 10px 0 10px -10px rgba(0, 0, 0, 0.12) inset;
      content: ' ';
      height: 100%;
      position: absolute;
      top: 0;
      right: -20px;
      width: 20px;
    }
  }
  .cx-sidebar-overlay {
    display: block;
    left: 0;

    @include mq(medium) {
      display: none;
    }
  }
  .cx-content-wrapper {
    @include mq(medium) {
      margin-left: $sideBarSizeXSmall;
    }
  }
}

.cx-sidebar-container {
  flex: 1 1 100%;
  overflow-y: auto;
  overflow-x: hidden;
  margin-bottom: auto;

  > header {
    border-bottom: 1px solid $red1;
    color: $gray1;
    text-align: left;
    height: 60px;
    font-size: 16px;
    cursor: pointer;

    a {
      padding: 10px 0 9px 0;

      display: block;

      img {
        height: 40px;
        margin: 0 5px 0 20px;
      }
      label {
        font-size: 30px;
        //border: 1px solid red;
        padding: 0;
        line-height: 1;
        position: relative;
        top: -5px;
        text-transform: uppercase;
      }

      small {
        display: block;
        padding: 0 0 0 66px;
        margin-top: -5px;
        font-size: 10px;
        font-style: italic;
        color: #8e8e8e;
      }
    }
  }
}
