@import '../assets/scss/_media-queries.scss', '../assets/scss/_variables.scss';

// Layout
#cx-wrapper {
  width: 100%;
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

#cx-app {
  flex: 1;
  display: flex;
  flex-direction: column;

  // position: relative;
  width: 100%;
  min-height: 100vh;
  margin: 0 auto;
  transition: left 0.3s ease, padding-left 0.3s ease;
}

// Content
.cx-content-wrapper {

  flex: 1;
  flex-direction: column;
  display: flex;
  //height: 100vh;

  @include mq(large) {
    margin-left: 0px;
  }
}

.cx-content {
  width: 100%;
  height: 100%;
  flex: 1 1 auto;
  background-color: white1;
  top: 60px;
  margin-bottom: auto;
}

.cx-footer {
  margin-top: auto;
  padding: 0 10px;
  flex: 0 0 30px;
  background-color: $red1;

  display: flex;

  p {
    font-size: 12px;
    color: $white1;
    padding: 8px 10px 0 10px;
    flex: 1 1 auto;

    &:last-child {
      flex: 0 0 auto;
      text-align: right;
    }
  }
}