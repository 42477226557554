@import '../../../../assets/scss/media-queries', '../../../../assets/scss/variables';

.cx-notebook-list {
  h3 {
    margin: 0 0 15px 8px;
  }

  .cx-notebook-list-item {
    margin: 0 8px 10px 8px;
    > div {
      padding: 10px 5px 0 5px;
      h4 {
        display: block;
        padding: 0 0 20px 4px;
        font-size: 15px;
        font-weight: bold;
        text-wrap: wrap;
        cursor: pointer;
      }
      .cx-notebook-list-item-actions {
        button {
          margin: 8px;
          span {
            display: none;
          }
        }

        div.cx-div-button-like-green {
          span {
            display: none;
          }
          display: inline-block;
          margin: 8px;
          padding: 0.375rem 0.75rem;
          line-height: 1.5;
          font-family: 'Jura', sans-serif;
          font-size: 1rem;
          font-weight: 400;
          color: $white1;
          background-color: #09b66d !important;
          border: 1px solid transparent;
          border-radius: 0;
          text-align: center;
          vertical-align: middle;
          user-select: none;
          transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out,
            box-shadow 0.15s ease-in-out;
          outline: none;
          &:hover {
            box-shadow: $boxShadow1;
          }
        }
      }

      .cx-notebook-list-item-shared-users {
        padding: 15px 0 15px 0;
        h4 {
          font-size: 13px;
        }
      }
    }
  }

  @include mq(small) {
    .cx-notebook-list-item {
      > div {
        .cx-notebook-list-item-actions {
          div:first-child,
          button:first-child {
            min-width: 150px;
          }
          button {
            span {
              display: inline-block;
            }
          }
          div.cx-div-button-like-green {
            span {
              display: inline-block;
            }
          }
        }
      }
    }
  }

  @include mq(large) {
    .cx-notebook-list-item {
      > div {
        padding-top: 0;
        > div:not(.cx-notebook-list-item-shared-users) {
          > h4 {
            flex: 1 1 50%;
            padding: 18px 0 0px 4px;
          }
          display: flex;
        }
        .cx-notebook-list-item-shared-users {
          background-color: #f9f9f9;
        }
      }
    }
  }
}
