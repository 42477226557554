@import './variables', './media-queries';

table.responsive-table {
  width: 100%;
  margin: 1em 0;
  border-collapse: collapse;
  box-shadow: $boxShadow1;

  th,
  td {
    text-align: left;
    border: 1px solid $lightGray3;
    padding: 0.5em 1em;
  }
  th {
    background: $white2;
  }
  td {
    background: $white0;
    td::before {
      content: '' !important;
    }
  }

  @media only screen and (max-width: 767px) {
    box-shadow: none;
    thead {
      display: none;
    }
    th,
    td {
      padding: 0.5em;
    }
    tr,
    td {
      display: block;
      &::before {
        content: attr(label);
        font-weight: lighter;
        width: 40%;
        float: left;
      }
      &::after {
        content: '';
        display: table;
        clear: both;
      }
    }
    tr {
      position: relative;
      margin-bottom: 1em;
      box-shadow: $boxShadow1;
    }
    td {
      border-top: none;
    }
  }
}
