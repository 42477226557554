@import '../../../assets/scss/_media-queries.scss', '../../../assets/scss/variables';

.cx-panel {
  box-shadow: $boxShadow1;
  background-color: $white0;
  text-align: left;
  > h2 {
    font-size: 18px !important;
    height: 40px;
    width: 100%;
    padding: 10px 15px 0 15px !important;
    color: $purple1;
    background-color: $lightGray1;
    border: none;
    border-bottom: 1px solid darken($lightGray1, 5%);

    span {
      text-transform: uppercase;
    }

    p {
      border-bottom: 3px solid $red1;
      width: 52px;
      padding-left: 15px;
      position: relative;
      top: -15px;
    }
  }
}
