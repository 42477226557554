@import '../../../../assets/scss/media-queries.scss';

.cx-loader {
  margin: 80px auto 30px auto;
}
.cx-admin-listing-edit-form {
  // .MuiInput-root, .MuiFormControl-root{
  //   //display: block;
  //   width: 100%;
  // }
  padding-top: 15px;

  form {
    padding: 0 15px;
  }
  .cx-panel {
    margin-bottom: 20px;

    > div {
      padding: 10px 15px 5px 15px;
      > div {
        //border: 1px solid red;
        margin: 0 0 15px 0;
      }
    }
  }

  h3 {
    padding: 10px 0 0 0;
    font-size: 16px;
    font-weight: 500;
  }

  .general {
    textarea {
      width: 100% !important;
    }
  }

  .coordinates {
    h3 {
      padding: 0 0 15px 0;
    }
    > div {
      display: flex;
      > div {
        padding: 0 4px 0 0;
      }
    }
    button {
      // border: 1px solid red;
      background-color: #eee;
      border-radius: 5px;
      padding: 12px 10px 4px 10px;
      i {
        font-size: 24px;
      }
    }
  }

  .mezzanine {
    > div {
      display: flex;

      // .toggle-field {
      //   margin: 8px 15px 0 0;
      // }
      > div:last-child {
        flex: 1 1 auto;
      }
    }
  }
}
