@import '../../../assets/scss/_variables.scss', '../../../assets/scss/media-queries';

.cx-todo {
  margin: 0 15px 15px 15px;
  flex: 1 1 100%;
  header {
    text-align: right;
    height: 1px;

    .cx-drop-down-menu {
      position: relative;
      top: -40px;
      i {
        font-size: 24px;
      }
    }
  }

  @include mq(small) {
    flex: 1 1 48%;
    margin: 0 15px 15px 0;
  }
  @include mq(large) {
    flex: 0 0 32%;
  }

  .cx-todo-items {
    form {
      padding: 5px;
      display: flex;

      > div {
        flex: 1 1 auto;
      }
      > div:last-child {
        flex: 0 0 auto;
        button {
          position: relative;
          top: 10px;
        }
      }
    }
  }
}
