@import './variables', './media-queries';

.box1 {
  margin: 0 15px 15px 15px;
  background-color: $white0;
  box-shadow: $boxShadow1;

  @include isTabletPortrait() {
    padding: 10px;
  }

  @include mq(medium) {
    padding: 10px;
  }
}
