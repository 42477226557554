@import '../../../../assets//scss/media-queries';

.cx-account-email-validation {
  h2 {
    display: block;
    padding: 10px 0 15px 15px;
    font-size: 24px;
  }

  .cx-panel {
    margin: 10px;
    .content {
      padding: 10px;
      p {
        padding: 10px 0 15px 15px;
      }
    }

    @include mq(medium) {
      width: 500px;
    }
  }

  .cx-form-buttons {
    input {
      margin-left: 10px;
    }
  }

  .cx-loader {
    margin-bottom: 100px;
  }
}
