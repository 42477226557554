@import '../../../assets/scss/media-queries.scss', '../../../assets/scss/apps.scss';

.cx-dashboard {
  display: flex;
  flex-direction: column;

  .cx-dashboard-dynamic {
    flex: 1 1 auto;
    padding-left: 15px;
  }
  .cx-dashboard-static {
    flex: 1 1 auto;
    .cx-panel {
      margin: 0 15px 15px 15px;
    }
  }

  @include mq(medium) {
    flex-direction: row;
    .cx-dashboard-dynamic {
      flex: 1 1 auto;
    }
    .cx-dashboard-static {
      flex: 0 0 300px;
      .cx-panel {
        margin: 0 15px 15px 0;
      }
    }
  }
}
