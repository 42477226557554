@import '../../../assets/scss/variables.scss', '../../../assets/scss/media-queries';

.cx-todo-search {
  .cx-todo-list {
    display: flex;
    flex-direction: column;
  }
  @include mq(small) {
    .cx-todo-list {
      flex-direction: row;
      flex-wrap: wrap;
      padding-left: 15px;
    }
  }

  .modal {
    .modal-body {
      padding: 0;
    }
  }

  .cx-empty-state {
    margin: 0px !important;
    min-width: auto !important;
    max-height: 100px;
    box-shadow: none;
  }
}
