@import './variables';

form > div {
  margin: 15px 0;
}

.input {
  display: block;
  box-sizing: border-box;
  width: 100%;
  border: 1px solid white;
  padding: 10px 15px;
  margin-bottom: 10px;
  font-size: 14px;
}

.input-error {
  display: block;
  padding: 5px;
  margin: 5px 0;
  border-radius: 3px;
  background-color: $red2;
  color: $white1;
  font-size: 12px;
}
.cx-form-buttons {
  text-align: right;
}

.cx-nobutton {
  border: 1px solid transparent;
  background: transparent;
  padding: 0.375rem 0.75rem;
  line-height: 1.5;
  font-family: 'Jura', sans-serif;
  font-weight: 400;
  display: inline-block;
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
  user-select: none;
  border-radius: 0;
  cursor: pointer;
}

.cx-button {
  display: inline-block;
  padding: 0.375rem 0.75rem;
  line-height: 1.5;
  font-family: 'Jura', sans-serif;
  font-size: 1rem;
  font-weight: 400;
  color: $white1;
  background-color: transparent;
  border: 1px solid transparent;
  border-radius: 0;
  text-align: center;
  vertical-align: middle;
  user-select: none;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out,
    box-shadow 0.15s ease-in-out;
  outline: none;
  &:hover {
    box-shadow: $boxShadow1;
  }
}
.cx-button-new {
  background-color: $green1;
  color: $white1;
  cursor: pointer;
}
.cx-button-save {
  background-color: $blue1;
  color: $white1;
  cursor: pointer;
}

.cx-button-edit {
  background-color: $gray1;
  color: $white2;
  cursor: pointer;
}
.cx-button-cancel {
  background-color: $lightGray2;
  color: $gray0;
  cursor: pointer;
}
.cx-button-delete {
  background-color: $red1;
  color: $white1;
  text-align: center;
  min-width: 40px;
  cursor: pointer;
}
.cx-button-action {
  background-color: $yellow1;
  color: $gray0;
  cursor: pointer;
}
.cx-button-simple {
  background-color: $lightGray2;
  color: $gray0;
  cursor: pointer;
}
.cx-button-link {
  background-color: transparent;
  color: $gray0;
  cursor: pointer;
  &:hover {
    text-decoration: underline;
    box-shadow: none;
  }
}

.cx-form-validation-message {
  color: red;
  background-color: rgb(253, 237, 237);
  display: block;
  padding: 4px 6px;
  margin: 1px 5px 0 5px;
}
