@import '../../../assets/scss/media-queries.scss';

.cx-collapse-panel {
  .collapsible {
    // background-color: #f2f;
    background-color: #ffffff;

    color: #333;
    font-family: MuseoSans500;
    font-size: 16px;
    text-align: left;
    cursor: pointer;
    padding: 10px 15px 0 15px;
    height: 44px;
    width: 100%;
    border: none;
    border-bottom: 1px solid #ddd;
    //border: 1px red solid;

    outline: none;

    span {
      text-transform: uppercase;
    }

    p {
      border-bottom: 3px solid #db2321;
      width: 52px;
      padding-left: 15px;
      position: relative;
      top: -17px;
    }

    i {
      float: right;
      color: #5e5e5e;
      transform: rotate(180deg);
      // transition: 1s ease-in-out;
    }

    .active {
      i {
        background-color: red;
      }
    }
  }

  .content {
    display: none;
    overflow: hidden;
    background-color: #fff;
    transition: max-height 0.2s ease-out;
  }

  @include mq(medium) {
    border: 1px solid #ddd;
    box-shadow: 0 10px 20px rgba(0, 0, 0, 0.19), 0 6px 6px rgba(0, 0, 0, 0.23);
  }
}
