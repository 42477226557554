@import '../../../assets/scss/_variables.scss';

.cx-header {
  background-color: $red1;
  height: 60px;
  min-height: 60px !important;
  color: $white0;
  display: flex;

  position: -webkit-sticky; /* Safari */
  position: sticky;
  top: 0;
  z-index: 9;

  .hamburger {
    flex: 0 0 60px;
    height: 60px;
    margin-left: 0px;
    background: transparent;
    border: none;
    outline: none;
    cursor: pointer;

    i {
      position: relative;
      top: 3px;
      left: 15px;
      font-size: 28px;
      color: $white2;
    }
  }
}
