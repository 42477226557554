@import '../../../../assets/scss/variables';
.cx-note-item {
  height: 80px;
  padding: 5px;
  border: 3px solid transparent;
  border-bottom: 2px solid $lightGray2;
  cursor: pointer;

  p {
    font-size: 16px;
    padding-bottom: 4px;
  }
  small {
    font-size: 11px;
  }
}
.cx-note-item-selected {
  border-left: 3px solid $yellow1;
  background-color: $white0;
}
