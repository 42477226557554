.cx-pre-notification {
  padding: 0 15px 25px 15px;
  .cx-card {
    padding: 5px;
    p {
      font-size: 22px;
      padding-bottom: 15px;
    }
  }
}
