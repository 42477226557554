@import '../../../assets/scss/media-queries', '../../../assets/scss/variables';

.cx-listing-dashboard {
  section {
    &:first-child {
      padding: 0 10px;
    }
    &:last-child {
      padding: 0 10px;
    }
  }
  .cx-panel {
    margin: 0 0 10px 0;
  }

  .quick-actions {
    ul {
      width: 100%;
      li {
        display: block;
        width: 100%;
        padding: 7px 15px;
        &:hover {
          background-color: $lightGray1;
          cursor: pointer;
        }
      }
    }
  }

  @include mq(medium) {
    section {
      &:first-child {
        padding: 0 10px;
      }
      &:last-child {
        padding: 0 10px 0 0;
      }
    }
  }
}
