@import '../../../assets/scss/_variables.scss', '../../../assets/scss/media-queries';

.cx-wines-item {
  margin: 0 15px 15px 15px;
  padding: 10px;
  background-color: $white0;
  min-height: 110px;
  color: $purple1;
  box-shadow: $boxShadow1;
  cursor: pointer;
  display: flex;
  .cx-wines-item-info {
    width: 100%;
    h2 {
      font-weight: bold;
      font-size: 17px;
      min-height: 30px;

      > span.wine-name {
        width: 78%;
        float: left;
        padding-bottom: 10px;
      }
      > span.wine-abv {
        background-color: $lightGray2;
        border-radius: 0 0 0 3px;
        width: 40px;
        height: 26px;
        padding: 5px;
        text-align: center;
        font-size: 13px;
        font-weight: 600;
        float: right;
        position: relative;
        top: -10px;
        right: -10px;
      }
    }

    small.wine-style,
    small.brewery {
      display: block;
      padding: 2px 0;
      &:hover {
        cursor: pointer;
      }
    }
    small.wine-when {
      font-size: 10px;
      position: relative;
      top: 5px;
    }
  }

  .cx-wines-item-image {
    flex: 0 0 125px;
    padding-right: 10px;
    //border: 1px solid red;

    img {
      max-width: 125px;
      max-height: 150px;
      border-radius: 3px;
    }
    i {
      color: $lightGray4;
      font-size: 74px;
      position: relative;
      top: 5px;
      left: 10px;
      border-radius: 3px;
    }
  }

  .modal-body {
    overflow: hidden;
    padding: 0;
    text-align: center;
    img {
      max-width: 100%;
      max-height: 100%;
    }
  }

  @include mq(medium) {
    .cx-wines-item-info {
      h2 {
        > span {
          &:first-child {
            width: 95%;
          }
        }
      }
    }
  }
}
