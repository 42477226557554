@import '../../../assets/scss/variables', '../../../assets/scss/media-queries';
.cx-notes-nav {
  flex: 0 0 100%;
  height: 100%;
  background-color: $white0;
  overflow-y: auto;

  @include mq(small) {
    flex: 0 0 260px;
  }

  .cx-notes-nav-container {
    position: -webkit-sticky; /* Safari */
    position: sticky;
    top: 0;
    z-index: 8;
  }

  .cx-notes-nav-toggle {
    position: relative;
    top: -1px;
    background-color: $gray4;
    text-align: right;
    button {
      color: $white0;
      padding: 14px 10px 10px 10px;
    }
  }
  .cx-notes-nav-top-actions {
    padding: 0 10px 0 10px;
    background-color: $white0;
  }
  .cx-button-new {
    margin: 15px 0 0 0;
    width: 100% !important;
    text-align: left;

    > i {
      position: relative;
      top: 3px;
    }
  >  span {
      margin-left: 6px;
    }
  }

  .MuiCollapse-container {
    padding-left: 10px;
  }

  .MuiFormControl-root {
    width: 100% !important;
  }
  .MuiListSubheader-gutters {
    padding-left: 0 !important;
  }
  .MuiListItemIcon-root {
    min-width: 25px !important;
  }
}

.cx-notes-nav-compact-container {
  text-align: center;
  button.cx-button {
    width: 50px !important;
    margin: 15px 0 0 0 !important;
    text-align: center;
    border-radius: 3px;
  }
}

.cx-notes-nav-expanded-container {
  .cx-notes-nav-notebooks {
    margin-right: 10px;
   > i {
      top: 2px;
      position: relative;
    }
  }
}

.cx-notes-nav-compacted {
  flex: 0 0 60px;
  bottom: 0;
  -webkit-transition: right 0.3s ease;
  transition: right 0.3s ease;

  .cx-notes-nav-toggle {
    text-align: center;
    button {
      padding: 14px 10px 10px 10px;
    }
  }
}
