@import '../../../../assets/scss/media-queries.scss', '../../../../assets/scss/variables.scss';

.cx-admin-listing-edit-photos {
  .upload-actions {
    margin: 5px 0 0 0;
    padding: 10px;
    background-color: $lightGray2;
    border: 1px dashed $lightGray3;
    cursor: pointer;
    text-align: center;
  }

  .cx-admin-listing-edit-photos-list {
    border-top: 1px solid #666;

    header {
      h2 {
        padding: 15px;
        font-size: 20px;
      }
    }

    .list {
      display: flex;
      flex-wrap: wrap;
      align-content: flex-start;
      .image-item {
        flex: 1 1 auto;
        max-width: 350px;

        img {
          width: 100%;
        }

        footer {
          padding: 5px;
          display: flex;
          button {
            padding: 0px 10px !important;
          }
        }
      }
    }
  }

  @include mq(large) {
    .cx-admin-listing-edit-photos-list {
      .list {
        .image-item {
          flex: 1 1 auto;
          max-width: 350px;
        }
      }
    }
  }
}
