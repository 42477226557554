@import '../../../assets//scss/forms';
.cx-empty-state {
  margin: 100px auto;
  padding: 25px;
  min-width: 500px;
  box-shadow: rgb(38, 57, 77) 0px 20px 30px -10px;
  h2 {
    font-size: 24px;
    padding-bottom: 20px;
  }
  > div {
    padding-top: 20px;
  }
}
