@import '../../../../assets/scss/media-queries.scss', '../../../../assets/scss/variables.scss';

.cx-listing-search {
  .cx-listing-search-search {
    padding: 15px;
    margin: 15px;
    margin-bottom: 25px;
    border-bottom: 1px solid $lightGray3;
  }
  .cx-listing-search-list {
    margin-bottom: 25px;
  }
}
