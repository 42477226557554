@import '../../../assets/scss/_media-queries.scss', '../../../assets/scss/_variables.scss',
  '../../../assets/scss/_boxes.scss';

.cx-password-manager {
  padding: 0 15px 15px 15px;
  .cx-panel {
    margin-bottom: 15px;
    .content {
      padding: 15px;
    }
  }

  .cx-password-manager-secrets-view {
    .modal-body {
      display: flex;
      flex-direction: column;
      row-gap: 10px;
      button {
        margin-right: 10px;
      }
      span {
        word-break: break-all;
      }
    }
  }

  .cx-password-manager-password {
    display: flex;
    flex-direction: row;
    > div {
      padding-bottom: 20px;
      &:nth-child(1) {
        flex: 1 1 auto;
      }
      &:nth-child(2) {
        flex: 0 0 100px;
        padding-left: 30px;
      }
      &:nth-child(3) {
        flex: 0 0 auto;
        font-size: 32px;
        position: relative;
        top: 10px;
        padding-left: 10px;
        i {
          cursor: pointer;
        }
      }
    }
  }
  @include mq(medium) {
  }
  @include mq(large) {
    max-width: 75vw;
  }

  .cx-password-manager-list {
    .cx-password-manager-list-total {
      text-align: right;
      position: relative;
      top: -45px;
      height: 5px;
    }
    ul {
      li {
        display: flex;
        padding: 5px;
        border-bottom: 1px solid $gray1;
        &:hover {
          background-color: $lightGray1;
        }
        &:last-child {
          border-bottom: none;
        }
        span {
          flex: auto;
          position: relative;
          top: 8px;
          cursor: pointer;
        }
        button {
          flex: 0 0 50px;
          margin-left: 10px;
        }
      }
    }
  }
  .modal-body {
    padding: 25px;
    code {
      background-color: #f1f1f1;
      padding: 10px;
      display: block;
    }
  }

  .cx-password-manager-search {
    padding: 15px 0;
    margin-bottom: 5px;
    [class='icon-plus'] {
      position: relative;
      top: 3px;
    }
  }
}

.cx-password-manager-sort {
  padding: 5px;
  width: 150px;
  h3 {
    color: $lightGray4;
    font-size: 11px;
  }
  ul {
    li {
      cursor: pointer;
      padding: 5px 0;
      display: block;
      border-bottom: 1px solid $lightGray2;
      &:last-child {
        border: none;
      }
      i {
        float: right;
        position: relative;
        padding: 0 6px 0 0;
        top: 3px;
      }
    }
  }
}
