@import '../../../assets/scss/variables';

.cx-tabs {
  /* Style the cx-tab */
  .cx-tab {
    overflow: hidden;
    border: 1px solid $lightGray2;
  }

  /* Style the buttons inside the cx-tab */
  .cx-tab button {
    background-color: inherit;
    float: left;
    border: none;
    outline: none;
    cursor: pointer;
    padding: 14px 16px;
    transition: 0.3s;
    font-size: 17px;
    i {
      position: relative;
      top: 2px;
    }
    > span {
      padding: 0 0 0 6px;
    }
  }

  /* Change background color of buttons on hover */
  .cx-tab button:hover {
    background-color: #ddd;
  }

  /* Create an active/current cx-tablink class */
  .cx-tab button.active {
    background-color: $pink1;
  }

  /* Style the cx-tab content */
  .cx-tab-content {
    display: block;
    border-top: none;
    height: 100vh;

    // -webkit-animation: fadeEffect 2s;
    // animation: fadeEffect 2s;
  }

  /* Fade in cx-tabs */
  @-webkit-keyframes fadeEffect {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }

  @keyframes fadeEffect {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }
}
