@import '../../../../assets/scss/variables', '../../../../assets/scss/media-queries';

.cx-note-item-edit {
  height: 100%;

  .cx-note-item-edit-title {
    textarea {
      font-size: 20px;
      width: 100%;
      padding: 20px 10px 10px 10px;
      overflow: hidden;
    }

    padding-bottom: 10px;
  }

  .cx-note-item-edit-editor {
    overflow: hidden;
    display: flex;
    /* Use flex to make children stretch to fill height */
  }

  .cx-admin-footer-actions {
    display: flex;
    flex-wrap: wrap;
    //border:1px solid red;

    .cx-button-cancel {
      flex: 0 0 auto;
      align-self: flex-end;
    }

    .cx-button-delete {
      float: left;
    }

    .cx-button-save {
      flex: 1 1 auto;
    }

    .cx-note-item-edit-updated {
      text-align: right;
      font-size: 13px;
      padding: 5px 5px 0 5px;
      order: 4;
    }
  }

  @include mq(medium) {
    .cx-admin-footer-actions {
      display: block;
      


      .cx-note-item-edit-updated {
        display: block;
        padding-right: 5px;
      }
    }
  }
}

.sun-editor {
  height: 100%;
}

.sun-editor .se-wrapper {
  height: calc(100% - 94px - 16px) !important;
}