@import '../../../../assets/scss/_media-queries.scss', '../../../../assets/scss/_variables.scss',
  '../../../../assets/scss/_grid.scss', '../../../../assets/scss/_boxes.scss';

.cx-account-profile {
  h2 {
    display: block;
    padding: 10px 0 15px 15px;
    font-size: 24px;
  }

  form {
    padding: 0 10px;
  }

  // Sections wrapper.
  > div {
    .cx-account-profile-photo {
      text-align: center;
      .photo {
        font-size: 100px;

        .no-photo {
          background-color: #666;
          color: #fff;
          max-width: 140px;
          margin: 10px auto;
        }
      }
      .upload-actions {
        margin: 5px 0 0 0;
        padding: 10px;
        background-color: $lightGray2;
        border: 1px dashed $lightGray3;
        cursor: pointer;
      }
    }
  }
}
