@import '../../../../assets/scss/variables', '../../../../assets/scss/media-queries';
.cx-notes {
  display: flex;
  border-top: 5px solid $gray4;
  border-bottom: 5px solid $gray4;
  .cx-notes-container {
    flex: 1 1 auto;
    display: flex;

    .cx-notes-container-list {
      flex: 0 0 100%;
    }

    .cx-empty-state {
      margin: 0px !important;
      min-width: auto !important;
      max-height: 100px;
      box-shadow: none;
      h2 {
        font-size: 18px;
      }
    }

    .cx-note-item-edit {
      flex: 1 1 auto;
    }
  }

  @include mq(small) {
    .cx-notes-container {
      .cx-notes-container-list {
        flex: 0 0 31%;
      }

      .cx-empty-state {
        flex: 0 0 31%;
      }
    }
  }
  @include mq(medium) {
    .cx-notes-container {
      .cx-notes-container-list {
        flex: 0 0 30%;
      }
    }
  }
  @include mq(large) {
    .cx-notes-container {
      .cx-notes-container-list {
        flex: 0 0 25%;
      }
    }
  }
}
