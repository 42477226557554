@import '../../../../assets//scss/media-queries';

.cx-account-register {
  h2 {
    display: block;
    padding: 10px 0 15px 15px;
    font-size: 24px;
  }

  .cx-panel {
    margin: 10px;
    .content {
      padding: 10px;
    }

    @include mq(medium) {
      width: 500px;
    }
  }

  .cx-form-buttons {
    .forgot-password-link {
      float: left;
      padding-top: 10px;
    }
    input {
      margin-left: 10px;
    }
  }

  .cx-loader {
    margin-bottom: 100px;
  }
}
