$red1: #ff3d57;
$red2: #fd364e;
$pink1: #ffe1e5;

$green1: #09b66d;

$blue1: #1976d2;
$blue2: #ddebf8;

$purple1: #34314c;

$yellow1: #ffaf38;

$white0: #fff;
$white1: #fafafa;
$white2: #f6f6f6;

$lightGray0: lighten(#000, 98%);
$lightGray1: lighten(#000, 97%);
$lightGray2: lighten(#000, 93%);
$lightGray3: lighten(#000, 89%);
$lightGray4: lighten(#000, 80%);
$lightGray5: #a6aaad;

$gray0: #000000;
$gray1: #212121;
$gray2: lighten(#212121, 5%);
$gray3: lighten(#212121, 10%);
$gray4: #666;

$boxShadow1: rgba(0, 0, 0, 0.12) 0px 1px 3px, rgba(0, 0, 0, 0.24) 0px 1px 2px;
