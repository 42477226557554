.cx-rating {
  > .cx-rating-labels {
    display: flex;
    > label {
      //border: 1px solid red;
      padding: 10px 0 0 0;
      font-size: 18px;
      font-weight: 500;
      display: block;

      flex: 1 1 auto;
      &:last-child {
        text-align: right;
      }
    }
  }
}
