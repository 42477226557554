@import '../../../assets/scss/variables', '../../../assets/scss/media-queries';
.cx-beers-edit {
  padding: 0 15px 30px 15px;

  .cx-beers-edit-img {
    text-align: center;
    img {
      width: 100%;
      max-width: 100%;
    }
  }

  .cx-beers-edit-fields {
    > div {
      margin: 15px 0;
    }
  }

  .upload-actions {
    margin: 5px 0 0 0;
    padding: 10px;
    background-color: $lightGray2;
    border: 1px dashed $lightGray3;
    cursor: pointer;
  }

  @include mq(medium) {
    .cx-beers-edit-form {
      display: flex;
      flex-direction: row;
      .cx-beers-edit-img {
        flex: 0 0 30%;
        order: 2;
        padding-top: 15px;
      }
      .cx-beers-edit-fields {
        padding: 15px;

        flex: 1 1 auto;
        order: 1;
      }
    }
  }
}
