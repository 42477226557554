@import '../../../../assets/scss/media-queries';

.cx-dashboard-myprofile {
  .content {
    text-align: center !important;
  }
  img {
    // max-height: 200px;
    max-width: 266px;
    margin: 10px;
    border-radius: 2px;
  }
  .cx-dashboard-myprofile-info {
    padding: 10px 15px;
    text-align: left;
    h3 {
      border-top: 1px solid #ddd;
      padding: 10px 0 10px 0;
      font-size: 18px;
      font-weight: bold;
    }
    p {
      padding: 0 0 10px 0;
    }
  }

  @include mq(small) {
    .content {
      text-align: left !important;
    }
  }
}
