@import '../../../../assets//scss/media-queries';

.cx-account-login {
  h2 {
    display: block;
    padding: 10px 0 15px 15px;
    font-size: 24px;
  }

  .cx-panel {
    margin: 10px;
    .content {
      padding: 10px;
    }

    @include mq(medium) {
      max-width: 500px;
    }
  }

  .cx-form-buttons {
    .forgot-password-link {
      float: left;
      padding-top: 10px;
    }
  }

  .account-register-link {
    margin: 20px 0 0 20px;
  }
}
