@import '../../../assets/scss/variables';

.cx-card {
  /* Add shadows to create the "card" effect */
  box-shadow: $boxShadow1;
  transition: 0.3s;
  background-color: $white0;
}

/* On mouse-over, add a deeper shadow */
.cx-card:hover {
  box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.2);
}

/* Add some padding inside the card container */
.cx-card-container {
  padding: 2px 16px;
  display: block;
}
