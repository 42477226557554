@import '../../../../assets/scss/variables', '../../../../assets/scss/media-queries';

.cx-note-search {
  padding: 0 5px 5px 5px;
  background-color: $gray4;

  > small {
    font-size: 12px;
    color: #fff;
  }
}

.cx-note-list {
  height: 86%;
  width: 100%;
  display: block;
  overflow-y: scroll;
  border-left: 2px solid $lightGray2;

  ul {
    border-top: 2px solid lighten($gray4, 40%);
  }
}
