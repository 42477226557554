@import './media-queries';

// Grids are build with a 3 column base.

.grid21 {
  display: flex;
  flex-direction: column;

  .grid21-item {
    flex: 0 0 100%;
  }

  @include mq(medium) {
    flex-direction: row;
    .grid21-item {
      &:first-child {
        flex: 1 1 auto;
      }
      &:last-child {
        flex: 0 0 300px;
        margin-left: 0;
      }
    }
  }
}

.grid {
  display: flex;
  flex-wrap: wrap;

  .grid-item {
    flex: 0 0 100%;
  }

  @include mq(medium) {
    .grid-item {
      flex: 1;
      //padding: 1em 0 0 1em;
    }
  }
}

/*

<div class="wrapper">
    <div class="container">
<!-- 1/4 Grid -->
      <div class="grid">
        <div class="grid-item">
          <div class="demo">1/4</div>
        </div>
        <div class="grid-item">
          <div class="demo">1/4</div>
        </div>
        <div class="grid-item">
          <div class="demo">1/4</div>
        </div>
        <div class="grid-item">
          <div class="demo">1/4</div>
        </div>
      </div>
<!-- 1/2 Grid -->
      <div class="grid">
        <div class="grid-item">
          <div class="demo">1/2</div>
        </div>
        <div class="grid-item">
          <div class="demo">1/2</div>
        </div>
      </div>
<!-- 1/3 Grid -->
      <div class="grid">
        <div class="grid-item">
          <div class="demo">1/3</div>
        </div>
        <div class="grid-item">
          <div class="demo">1/3</div>
        </div>
        <div class="grid-item">
          <div class="demo">1/3</div>
        </div>
      </div>
<!-- Full Width Grid -->
      <div class="grid">
        <div class="grid-item">
          <div class="demo">Full</div>
        </div>
      </div>
<!-- Altertnating / Nested Grid -->
      <div class="grid">
        <div class="grid-item">
          <div class="demo">1/2</div>
        </div>
        <div class="grid-item">
          <div class="grid">
            <div class="grid-item">
              <div class="demo">1/4</div>
            </div>
            <div class="grid-item">
              <div class="demo">1/4</div>
            </div>
          </div>
        </div>
      </div>
*/
