@import '../../../../assets/scss/variables';

.cx-admin-poi-edit-form {
  .distance {
    display: flex;
    > div {
      flex: 1 1 50%;
      padding: 6px 0;
      &:first-child {
        padding-right: 4px;
      }
      &:last-child {
        padding-left: 4px;
      }
    }
    .MuiFormControl-marginNormal {
      margin: 0;
    }
  }
}
