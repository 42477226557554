@import '../../../assets/scss/variables';
.cx-wines {
  padding-bottom: 30px;
  .cx-empty-state {
    margin: 0px !important;
    min-width: auto !important;
    max-height: 100px;
    box-shadow: none;
  }

  .cx-wines-total{
    font-size: 14px;
    color: lighten(#000, 60%);
    margin: 0 0 10px 20px;
  }

  .cx-wines-search {
    padding: 0 15px;
    margin-bottom: 15px;
    [class='icon-plus'] {
      position: relative;
      top: 3px;
    }
  }
}

.cx-wine-search-sort {
  padding: 5px;
  width: 150px;
  h3 {
    color: $lightGray4;
    font-size: 11px;
  }
  ul {
    li {
      padding: 5px 0;
      display: block;
      border-bottom: 1px solid $lightGray2;
      &:last-child {
        border: none;
      }
      i {
        float: right;
        position: relative;
        padding: 0 6px 0 0;
        top: 3px;
      }
    }
  }
}

#load-more-wines {
  min-width: 90%;
  margin: 15px 15px 30px 15px;
  padding: 6px 60px;
}
