@import '../../../../assets/scss/_variables.scss';

.cx-admin-poi-list-item {
  $itemGray: #676767;
  //border: 1px solid #ddd;

  .cx-admin-poi-list-item-content {
    display: flex;
    //  padding: 14px 4px 11px 0;
    color: $itemGray;

    .icon {
      color: $itemGray;
      font-size: 24px;
      padding: 0 10px 0 0;
    }

    .cx-admin-poi-list-item-content-body {
      display: block;
      position: relative;
      top: 3px;
      width: 100%;
      .title {
        padding-bottom: 11px;
        line-height: 1;
        font-size: 18px;
      }
      .specs {
        width: 100%;
        display: block;
        display: flex;

        i {
          color: $red1;
        }
        > div {
          flex: 1 1 auto;
          &:first-child {
            flex: 0 0 95px;
          }
        }
      }
    }
  }
}
