@import './_variables.scss', './media-queries';

.cx-user-apps {
  display: flex;
  flex-wrap: wrap;
  .cx-user-apps-item {
    flex: 1 1 100%;

    @include mq(small) {
      flex: 1 1 50%;
    }
    @include mq(medium) {
      flex: 1 1 100%;
    }

    @include mq(large) {
      flex: 1 1 50%;
    }

    .cx-user-apps-item-container {
      margin: 0 15px 15px 0;
      padding: 10px;
      background-color: $white0;
      min-height: 110px;
      color: $purple1;
      box-shadow: $boxShadow1;
      cursor: pointer;

      h2 {
        font-size: 24px;
        margin-bottom: 4px;
        i {
          color: lighten($color: $gray1, $amount: 25%);
          font-size: 32px;
          margin-right: 10px;
        }
        span {
          position: relative;
          top: -7px;
        }
      }
      p {
        color: lighten($purple1, 30%);
      }
    }
  }
}

// // Panel colors
// /*
// 	8dbf41
// 	f15f43
// 	3498DB
// 	EFC94C
// */
// .dLink0 {
//   background-color: #428bca;
// }

// .dLink1 {
//   background-color: #ff9e02;
// }

// .dLink2 {
//   background-color: #960028;
// }

// .dLink3 {
//   /*background-color: #3498DB;*/
//   background-color: #88c85d;
// }

// .dLink4 {
//   background-color: #7eb12c;
// }

// .dLink5 {
//   background-color: #f35d23;
// }

// .dLink6 {
//   background-color: #654566;
// }

// .dLink7 {
//   background-color: #428bca;
// }

// .dLink8 {
//   background-color: #343a40;
// }
