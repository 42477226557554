/*
    Media Queries
    Provide abstraction for media queries.   
*/
$size-unit: 1px;

$xs-min: 576;
$sm-min: 768;
$md-min: 992;
$lg-min: 1200;

$screen-xs-min: $xs-min * $size-unit !default;
$screen-sm-min: $sm-min * $size-unit !default;
$screen-md-min: $md-min * $size-unit !default;
$screen-lg-min: $lg-min * $size-unit !default;

// So media queries don't overlap when required, provide a maximum
$screen-xs-max: ($screen-sm-min - 1);
$screen-sm-max: ($screen-md-min - 1);
$screen-md-max: ($screen-lg-min - 1);

@mixin mq($point) {
  @if $point == large {
    @media (min-width: $screen-lg-min) {
      @content;
    }
  } @else if $point == medium {
    @media (min-width: $screen-md-min) {
      @content;
    }
  } @else if $point == small {
    @media (min-width: $screen-sm-min) {
      @content;
    }
  } @else if $point == xsmall {
    @media (max-width: $screen-xs-max) {
      @content;
    }
  }
}

@mixin isTabletPortrait() {
  @media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: portrait) {
    @content;
  }
}

@mixin isTabletLandscape() {
  @media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: landscape) {
    @content;
  }
}

// Responsive Utils
$medContentColumnWith: 641;
$largeContentColumnWith: 820;
