@import './assets//scss/_reset.scss', './assets/scss/_variables.scss', './assets//scss/_fonts.scss',
  './assets//scss/_forms.scss', './assets//scss/material-overwrites.scss', './assets//scss/_tables.scss';
@import url('https://fonts.googleapis.com/css2?family=Jura:wght@300;500;700&display=swap');

html,
body {
  height: 100%;
  background-color: $white1 !important;
  font-family: 'Jura', sans-serif;
}

.clearfix::after {
  content: '';
  display: table;
  clear: both;
}

.cx-admin-top-actions {
  text-align: right;
  padding: 10px;
  button {
    margin: 5px;
  }
}
.cx-admin-footer-actions {
  text-align: right;
  button {
    margin: 5px;
  }
}

* {
  scrollbar-width: thin;
  scrollbar-color: $lightGray1 $gray4;
}

/* Works on Chrome, Edge, and Safari */
*::-webkit-scrollbar {
  width: 12px;
}

*::-webkit-scrollbar-track {
  //  background: orange;
}

*::-webkit-scrollbar-thumb {
  background-color: $gray4;
  border-radius: 20px;
  border: 3px solid $lightGray1;
}
