@import '../../../assets/scss/_variables.scss', '../../../assets/scss/media-queries';

.cx-vehicles-item {
  margin: 0 15px 15px 15px;
  padding: 10px;
  background-color: $white0;
  min-height: 110px;
  color: $purple1;
  box-shadow: $boxShadow1;
  cursor: pointer;
  display: flex;
  .cx-vehicles-item-info {
    width: 100%;
    h2 {
      font-weight: bold;
      font-size: 20px;
      min-height: 30px;    
    }

    p{
      font-size: 14px;
      color: $gray1;
      margin: 0 0 6px 0;
    }

  
  }

  .cx-vehicles-item-image {
    flex: 0 0 125px;
    padding-right: 10px;
    //border: 1px solid red;

    img {
      max-width: 125px;
      max-height: 150px;
      border-radius: 3px;
    }
    i {
      color: $lightGray4;
      font-size: 74px;
      position: relative;
      top: 5px;
      left: 10px;
      border-radius: 3px;
    }
  }

  .modal-body {
    overflow: hidden;
    padding: 0;
    text-align: center;
    img {
      max-width: 100%;
      max-height: 100%;
    }
  }

  @include mq(medium) {
    .cx-vehicles-item-info {
      h2 {
        > span {
          &:first-child {
            width: 95%;
          }
        }
      }
    }
  }
}
