@import '../../../../assets/scss/media-queries', '../../../../assets/scss/variables';

.cx-notebooks {
  display: flex;
  border-top: 5px solid $gray4;
  border-bottom: 5px solid $gray4;
  .cx-notebooks-container {
    flex: 1 1 auto;

    .cx-notebooks-selected {
      border-left: 2px solid $yellow1;
    }

    .cx-notebooks-list-container {
      display: flex;
      flex-direction: column;

      .cx-notebooks-list {
        overflow: scroll;
      }
    }
  }
}
.cx-notebooks-delete-modal {
  .modal-content {
    max-width: 500px !important;

    p {
      font-style: italic;
      font-weight: bold;
      padding: 10px;
    }
  }
}
