@import '../../../../assets//scss/media-queries';

.cx-account-password-recover {
  h2 {
    display: block;
    padding: 10px 0 15px 15px;
    font-size: 24px;
  }

  #accountPasswordRecoverForm {
    width: 100%;
    @include mq(small) {
      min-width: 420px;
    }
  }

  .cx-panel {
    margin: 10px;
    .content {
      padding: 10px;
      p {
        padding: 10px 0 15px 15px;
      }
    }

    @include mq(medium) {
      width: 500px;
    }
  }

  .cx-form-buttons {
    .new-code {
      float: left;
    }
    input {
      margin-left: 10px;
    }
  }

  .cx-loader {
    margin-bottom: 100px;
  }

  .cx-password-rules {
    ul {
      li {
        padding-top: 15px;
        margin-left: 25px;
        list-style-type: disc;
      }
    }
  }

  @include mq(small) {
    > div {
      display: flex;
    }
  }
}
