.cx-header-actions-user {
  float: right;
  padding: 20px 15px 0 0;
  height: 60px;
  font-size: 18px;
  cursor: pointer;
  span {
    position: relative;
    top: -3px;
  }
  i {
    margin: 5px 10px 0 0;
  }
}
