@import '../../../../assets/scss/media-queries.scss';

.cx-admin-listing-edit-amenities {
  .amenities-container {
    display: block;
    > div {
      label {
        display: block;
      }
    }
  }
}

@include mq(small) {
  .amenities {
    .amenities-container {
      display: flex;
      flex-wrap: wrap;
      > div {
        flex: 1 1 auto;
      }
    }
  }
}
