@import '../../../../assets//scss//variables';

.cx-dashboard-quick-actions {
  ul {
    width: 100%;
    li {
      display: block;
      width: 100%;
      padding: 7px 15px;
      &:hover {
        background-color: $lightGray1;
        cursor: pointer;
      }
    }
  }
}
