.cx-diagnostics {
  padding: 25px;
  font-size: 48px;
  h2 {
    padding-bottom: 25px;
  }
  ul {
    li {
      font-size: 18px;
      font-weight: bold;
      span {
        font-weight: normal;
      }
    }
  }
}
