@import '../../../../assets/scss/media-queries.scss', '../../../../assets/scss/variables.scss',
  '../../../../assets/scss/boxes';

.cx-listings-list-item {
  &:hover {
    cursor: pointer;
  }

  //  border: 1px solid red;
  display: flex;
  flex-direction: column;

  .cx-listings-list-item-image {
    flex: 0 0 auto;
    //height: 200px;
    img {
      width: 100%;
      max-height: 200px;
    }
    span {
      float: left;
      position: relative;
      top: -30px;

      padding: 5px 10px 0 10px;
      height: 30px;
      width: 100%;
      background-color: rgba(0, 0, 0, 0.6);
      color: $white0;
      font-weight: bold;
    }

    .cx-no-image-placeholder {
      min-width: 200px;
      min-height: 200px;
      i {
        position: relative;
        // top: -15px;
      }
    }
  }
  .cx-listings-list-item-content {
    flex: 1 1 auto;
    //border: 1px solid blue;

    .cx-listings-list-item-content-address {
      padding: 5px;
      font-size: 16px;
      line-height: 1.5;
    }
    ul {
      display: flex;
      padding: 8px 0;
      li {
        flex: 1 1 auto;
        text-align: center;
        // display: flex;
        // flex-direction: column;
        i {
          font-size: 18px;
          color: $lightGray5;
          //margin-right: 5px;
        }
        span {
          //display: block;
          font-size: 12px;
          position: relative;
          top: -3px;
          color: $gray2;
          padding: 0 0 0 5px;
        }
      }
    }
  }

  @include isTabletPortrait() {
    flex-direction: row;
    max-height: 170px;
    .cx-listings-list-item-image {
      max-width: 200px;
      max-height: 170px;
    }

    .cx-listings-list-item-content {
      flex: 1 1 auto;

      .cx-listings-list-item-content-address {
        padding-top: 0;
      }

      ul {
        flex-direction: row;
        padding-left: 5px;
        li {
          text-align: left;
        }
      }
    }

    .cx-admin-footer-actions > button {
      position: relative;
      top: 13px;
    }
  }

  @include mq(large) {
    flex-direction: row;
    //  max-height: 170px;

    .cx-listings-list-item-image {
      max-width: 200px;
      //  max-height: 170px;
      margin-right: 10px;

      .cx-no-image-placeholder {
        min-height: 150px !important;
        border: 1px solid $gray0;
        i {
          font-size: 18px;
          position: relative;
          top: -15px;
        }
      }
    }

    .cx-listings-list-item-content {
      flex: 1 1 auto;

      .cx-listings-list-item-content-address {
        padding: 0;
      }

      ul {
        flex-direction: row;
        li {
          text-align: left;
        }
      }
    }

    .cx-admin-footer-actions > button {
      position: relative;
      top: 13px;
    }
  }
}
