@import '../../../assets/scss/variables';
.cx-drop-down-menu {
  button {
    i {
      font-size: 20px;
      line-height: 1;
      position: relative;
      top: 2px;
    }
  }

  // Material overwrites
  .MuiSvgIcon-root {
    box-shadow: none !important;
    background-color: transparent !important;
    &:hover {
      background-color: $lightGray1 !important;
    }
    span {
      background-color: transparent;
      &:hover {
        background-color: transparent;
      }
    }
  }
}
