@import '../../../../assets/scss/_media-queries.scss', '../../../../assets/scss/apps.scss';

.cx-account-apps {
  padding: 15px 0 0 15px;

  .cx-account-apps-switch-container {
    padding: 4px 4px 0 0;
    float: right;
    top: 5px;
    > span {
      margin-right: 8px;
      top: -10px;
      position: relative;
      color: #ccc;
      font-size: 11px;
    }
    .p-inputswitch {
      margin: 5px 5px 0 0;
    }
  }
}
