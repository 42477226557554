$borderRadious: 5px;




ul.cx-tree {
  margin: 0;
  padding: 0;
  width: auto;

  .highlight {
    background-color: #f0f0f0;
    border: 2px dashed #ccc;
    border-radius: $borderRadious;
  }
  
  i.icon-down-open-mini, i.icon-right-open-mini {
    position: relative;
    top: -1px;
    padding: 1px 3px 1px 1px;
    font-size: 16px;
    cursor: pointer;
   // border:1px solid red;
  
    &.rotate {
      transform: rotate(180deg);
    }
  }

  .root {
    margin-left: 20px;
  }

  ul {
    margin: 0;
    padding: 0;
    padding-inline-start: 6px;
  }

  li {
    margin: 0;
    padding: 0;
    border: 1px solid transparent;
    list-style-type: none;
  
    .cx-tree-item {
      width: 90%;
      display: inline-block;
      padding: 12px 4px 6px 4px;
      cursor: pointer;

      &:hover {
        background-color: #f4f4f4;
        border-radius: $borderRadious;      
      }

      &.cx-tree-selected {
        background-color: rgba(25, 118, 210, 0.08);
        border-radius: $borderRadious;
      }

      i.icon-repo {
        position: relative;
        top: -2px;
        font-size: 14px;
        padding: 1px 3px 1px 1px;
      }

      span.cx-tree-item-name {
        position: relative;
        top: -4px;
        font-size: 13px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      
      //  border: 1px solid red;

        i {
          float: right;
          position: relative;
          top: 5px;
        }
      }
    }

    .cx-tree-item-default {
      font-style: italic;
      font-weight: 600;
    }

    
  }


}